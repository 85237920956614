<template>
  <div id="site" class="header-fixed">
    <header class="app-header fixed">
      <div class="app-header-container container">
        <div class="row">
          <img src="@/assets/img/logo.svg" alt="logo" class="site-logo"/>
          <span class="app-header-title">Сall Recorder</span>
        </div>
      </div>
    </header>
    <div class="main-content">
      <slot/>
    </div>
    <footer class="purchase-footer">

      <div>
        <ul class="politics">
          <li>
            <router-link :to="{name:'terms'}">Terms of Use</router-link>
          </li>
          <li>
            <router-link :to="{name:'refund'}">Refund Policy</router-link>
          </li>
          <li>
            <router-link :to="{name:'policy'}">Privacy Policy</router-link>
          </li>
          <li>
            <router-link :to="{name:'contact'}">Contact us</router-link>
          </li>
        </ul>

      </div>

      <div class="info">
        operated by ZEMETO based on the agreement<br>
        Zemeto Limited, 3021<br>
        Pavlou Nirvana & Aipeias, 4 Alpha Tower, 1st Floor, Flat/Office 11<br>
        Republic of Cyprus<br>
      </div>
    </footer>

    <!--    <footer class="main-footer">-->
    <!--      <div class="footer-bottom">-->
    <!--        <div class="footer-copyright">2021 ⓒ All Rights Reserved.</div>-->
    <!--        <div class="footer-agreement">-->
    <!--          Devurum Limited - HE 368090<br/>-->
    <!--          Agiou Marona 19, Lakatamia, Nicosia, P.C. 2304, Republic of Cyprus.<br/>-->
    <!--          <ul class="footer-politics-container">-->
    <!--            <li><a href="https://icall-recorder.com/onboarding/terms/">Terms of Service</a>|</li>-->
    <!--            <li><a href="https://icall-recorder.com/onboarding/policy/">Privacy policy</a>|</li>-->
    <!--            <li><a href="https://icall-recorder.com/onboarding/refund/">Money-Back Policy.</a></li>-->
    <!--          </ul>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--    </footer>-->

  </div>
</template>

<script>
export default {
  name: 'AppLayout'
}
</script>

<style lang="scss" scoped>
.purchase-footer {
  display: flex;
  padding-bottom: 25px;
  justify-content: space-around;
  color: #AEAEAE;
  font-size: 13px;

  .info {
    text-align: center;
  }

  div {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      margin-top: 3%;

      a {
        font-size: 13px;
        color: #AEAEAE;
        text-decoration: none;
      }
    }
  }

  .politics {
    display: flex;
    justify-content: center;
    align-content: flex-start;
    flex-wrap: wrap;

    li:first-child {
      list-style: none;
    }

    li {
      margin: 0 14px;

      a {
        font-size: 13px;
        text-decoration: none;
        color: #AEAEAE;
      }
    }
  }
}

#site {
  min-height: calc(100vh - 50px);
  background: #F4F4F8;

  &.header-fixed {
    margin-top: 70px;
  }

  .app-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;
    padding: 0 50px;
    background: #fff;
    z-index: 60;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.07);

    .app-header-container {
      height: 70px;
      display: flex;
      align-items: center;
      .row{
        display: contents;
      }
      .site-logo{
        width: auto;
      }
    }

    .app-header-title {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 20px;
      color: #000000;
    }

    &.fixed {
      position: fixed;
      top: 0;
      left: 0;
      transition: all linear 0.1s;
    }

    .site-logo {
      display: block;
      object-position: left center;
      object-fit: scale-down;
    }

    .page-title {
      font-family: 'Roboto', sans-serif;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: #F7A826;
    }

    .btn-back {
      position: relative;
      width: 198px;
      height: 40px;
      background: rgba(255, 255, 255, 0.15);
      border: none;
      border-radius: 3px;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #FFFFFF;
      cursor: pointer;

      .arrow {
        position: absolute;
        left: 18px;
        top: 50%;
        transform: translateY(-50%);
        transition: all .2s;
      }

      .icon-close {
        display: none
      }

      &:hover .arrow {
        left: 15px;
      }
    }
  }

  .main-content {
    background: #F4F4F8;
  }


  .main-footer {
    padding: 0 10%;
  }

  .footer-bottom {
    display: flex;
    justify-content: space-between;
    padding-bottom: 64px;
  }

  .footer-politics-container {
    display: flex;
    list-style: none;
    padding: 0;
  }

  .footer-politics-container {
    li:first-child {
      a {
        margin: 0 5px 0 0;
      }
    }

    li {

      a {


        font-size: 10px;
        line-height: 14px;
        color: #AEAEAE;
        margin: 0 5px;
      }
    }

  }

  .footer-copyright {
    font-size: 12px;
    line-height: 120%;
    color: #BCBCC6;
    text-align: left;
  }

  .footer-agreement {
    font-size: 14px;
    line-height: 21px;
    color: #AEAEAE;
    text-align: left;

  }

}
</style>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .purchase-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    //padding-bottom: 150px;
  }
  .purchase-footer div {
    width: 100%;
  }
  .purchase-footer div:nth-child(1) {
    order: 1;
    padding: 5% 5% 0;
    text-align: center;
  }
  .purchase-footer div:nth-child(2) {
    order: 0;

    .politics {
      display: flex;
      flex-direction: row;

      li {
        margin: 0 10px;
      }
    }
  }
  .purchase-footer div:nth-child(3) {
    order: 2;
    text-align: center;
  }
  #site {
    &.header-fixed {
      margin-top: 50px;
    }

    .app-header {
      height: 50px;
    }

    .main-footer {
      padding: 0px 5%;

      .footer-bottom {
        display: flex;
        flex-direction: column;
        align-items: center;

        .footer-copyright {
          text-align: center;
        }

        .footer-agreement {
          text-align: center;

          .footer-politics-container {
            justify-content: center;
          }
        }
      }
    }
  }


}

@media (max-width: 992px) {

  .purchase-footer {

    .politics {
      display: flex;

      flex-wrap: wrap;

      li {
        list-style: none;
        margin: 5px;
      }
    }
  }
}

@media (orientation: landscape) and (max-width: 1024px) {
  .purchase-footer {
    .politics {
      display: flex;
      flex-direction: row;

      li:nth-child(2) {
        padding: 0 10px;
      }
    }

    .info {
      width: 40%;
      text-align: center;
    }
  }

}
</style>

<style lang="scss" scoped>
@media (max-width: 325px) {
  .menu-wrapper {
    width: 300px;
  }
}
</style>
